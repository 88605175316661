import { useDisclosure, useElementSize, useMediaQuery } from "@mantine/hooks";
import { forwardRef } from "react";
import "../App.css";
import ImageWithFadeIn from "../components/FadeInImage";
import Header from "../components/Header";
import Layout from "../components/Layout";
import { customsWsLink } from "../utils/format";
import { Gallery } from "./label";

const images = Array(14)
  .fill(0)
  .map((_, index) => {
    return {
      original: `/assets/customs/${index + 1}.webp`,
      thumbnail: `/assets/customs/${index + 1}.webp`,
    };
  });

function Customs() {
  const menuDisclosure = useDisclosure(false);
  const modalDisclosure = useDisclosure(false);

  const { ref, width, height } = useElementSize();
  const matches = useMediaQuery("(max-width: 1023px)");

  return (
    <Layout menuDisclosure={menuDisclosure}>
      <main className="flex flex-col min-h-screen w-screen items-center justify-center bg-black">
        <Header menuDisclosure={menuDisclosure} />
        <div className="h-full w-full flex lg:items-center lg:justify-center lg:pt-[84px] p-4 md:px-[84px] lg:p-12">
          <div className="flex flex-col gap-12 md:gap-14 w-full 2xl:max-w-screen-xl py-6 md:py-0 xl:pt-0">
            <div className="flex flex-col w-full lg:flex-row gap-12 md:gap-16 2xl:gap-20">
              <div className="w-10/12 md:w-full flex-1 lg:flex-[0.8_1_0] 2xl:flex-1 basis-0">
                <ImageWithFadeIn
                  className="lg:h-auto lg:w-auto"
                  src="/assets/customs/kicks-like-rabbit.svg"
                  alt="Kicks Like Rabbit"
                />
              </div>
              <Sneakers ref={ref} />
              <MobileSneakers />
            </div>
            <div className="flex flex-col-reverse w-full lg:flex-row gap-12 2xl:gap-14">
              <div className="flex flex-col gap-8 md:gap-10 flex-1 xl:flex-[0.8_1_0] 2xl:flex-1 basis-0">
                <div className="flex flex-row gap-6 max-w-[310px]">
                  <div className="w-fit hover:scale-110 transform duration-150">
                    <a target="_blank" href={customsWsLink("Customs")}>
                      <ImageWithFadeIn
                        src="/assets/landing/Whatsapp.svg"
                        alt="Whatsapp"
                        className="h-10 cursor-pointer"
                      />
                    </a>
                  </div>
                  <div className="w-fit hover:scale-110 transform duration-150">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/kickslikerabbit/"
                    >
                      <ImageWithFadeIn
                        src="/assets/landing/Instagram.svg"
                        alt="Instagram"
                        className="h-10 cursor-pointer"
                      />
                    </a>
                  </div>
                  <div
                    className="border border-border select-none text-neutral-200 h-10 gap-2 px-3 min-w-20 w-full max-w-[182px] items-center justify-center cursor-pointer flex font-light hover:text-primary hover:border-primary duration-150 transition"
                    onClick={modalDisclosure[1].open}
                  >
                    GALLERY
                  </div>
                </div>
                <div className="flex flex-col gap-6 max-w-[310px]">
                  <div className="text-secondary-text flex items-center justify-center gap-6">
                    <span className="whitespace-nowrap font-thin text-lg">
                      PROUD OWNERS
                    </span>
                    <div className="w-full h-[1px] bg-secondary-text" />
                  </div>
                  <div className="flex flex-wrap justify-between 2xs:gap-4">
                    <ImageWithFadeIn
                      className="max-w-24"
                      src="/assets/customs/alacappa.svg"
                    />
                    <ImageWithFadeIn
                      className="max-w-24"
                      src="/assets/customs/u1.svg"
                    />
                    <ImageWithFadeIn
                      className="max-w-24"
                      src="/assets/customs/maeouzs.svg"
                    />
                    <ImageWithFadeIn
                      className="max-w-24"
                      src="/assets/customs/venkat.svg"
                    />
                    <ImageWithFadeIn
                      className="max-w-24"
                      src="/assets/customs/vijay.svg"
                    />
                    <ImageWithFadeIn
                      className="max-w-24"
                      src="/assets/customs/anirudh.svg"
                    />
                  </div>
                  <div className="text-text lg:text-end w-fit flex-col flex lg:hidden pt-6 pb-4 gap-1">
                    <span className="text-sm">DESIGNED BY:</span>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/rabbit.mac/"
                    >
                      <ImageWithFadeIn
                        className="w-fit"
                        src="/assets/signature/sign.svg"
                        alt="RABBITMAC"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full flex-1 basis-0 lg:items-end max-w-none xl:max-w-[600px] 2xl:pl-0 gap-14">
                {ref.current && !!width && (
                  <div
                    className="w-full flex-1 basis-0 max-w-none xl:max-w-[600px] 2xl:pl-0"
                    style={{
                      width: !matches ? width : "auto",
                      maxWidth: !matches ? width : "none",
                      minWidth: !matches ? width : "0px",
                    }}
                  >
                    <span className="text-text font-serif text-xs xl:text-sm">
                      Step into a world of limitless creativity with our bespoke
                      custom sneaker designs, perfect for making a bold
                      statement at events, promoting your organization,
                      enhancing your brand's identity, or adding a personal
                      flair to your everyday wardrobe. Each pair is meticulously
                      designed by our renowned founder, Rabbit Mac--an acclaimed
                      rapper and designer with a passion for blending art,
                      culture, and fashion. Whether you're hosting a corporate
                      event, launching a branded campaign, or simply looking to
                      express your unique style, our custom sneakers are
                      tailored to reflect your vision, ensuring that you stand
                      out with every step. With Rabbit Mac's signature touch,
                      every design tells a story, combining craftsmanship with
                      cutting-edge creativity for a truly one-of-a-kind
                      experience.
                    </span>
                  </div>
                )}
                {(!ref.current || !width) && (
                  <div className="w-full flex-1 basis-0 max-w-none xl:max-w-[600px] 2xl:pl-0">
                    <span className="text-text font-serif text-xs xl:text-sm">
                      Step into a world of limitless creativity with our bespoke
                      custom sneaker designs, perfect for making a bold
                      statement at events, promoting your organization,
                      enhancing your brand's identity, or adding a personal
                      flair to your everyday wardrobe. Each pair is meticulously
                      designed by our renowned founder, Rabbit Mac--an acclaimed
                      rapper and designer with a passion for blending art,
                      culture, and fashion. Whether you're hosting a corporate
                      event, launching a branded campaign, or simply looking to
                      express your unique style, our custom sneakers are
                      tailored to reflect your vision, ensuring that you stand
                      out with every step. With Rabbit Mac's signature touch,
                      every design tells a story, combining craftsmanship with
                      cutting-edge creativity for a truly one-of-a-kind
                      experience.
                    </span>
                  </div>
                )}

                <div className="text-text lg:text-end w-fit flex-col hidden lg:flex gap-1">
                  <span className="text-sm">DESIGNED BY:</span>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/rabbit.mac/"
                  >
                    <ImageWithFadeIn
                      className="w-fit"
                      src="/assets/signature/sign.svg"
                      alt="RABBITMAC"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Gallery
          handlers={modalDisclosure[1]}
          opened={modalDisclosure[0]}
          images={images}
        />
      </main>
    </Layout>
  );
}

export default Customs;

const SneakerImage = ({ src }: any) => {
  return (
    <ImageWithFadeIn
      className="h-fit aspect-square object-cover flex-1 basis-0 xl:h-full max-w-36 max-h-36 lg:max-w-28 lg:max-h-28 lgxl:max-w-36 lgxl:max-h-36"
      src={src}
      alt="Sneaker"
    />
  );
};

const Sneakers = forwardRef((props, ref: any) => {
  return (
    <div
      ref={ref}
      className="hidden md:flex flex-col xl:justify-center xl:items-center gap-2 w-fit lg:flex-1 basis-0"
    >
      <div className="flex gap-2 flex-1 basis-0 items-end">
        <SneakerImage src="/assets/sneakers/0-0.png" />
        <SneakerImage src="/assets/sneakers/0-1.png" />
        <SneakerImage src="/assets/sneakers/0-2.png" />
        <SneakerImage src="/assets/sneakers/0-3.png" />
      </div>
      <div className="flex gap-2 flex-1 basis-0 items-start">
        <SneakerImage src="/assets/sneakers/1-0.png" />
        <SneakerImage src="/assets/sneakers/1-1.png" />
        <SneakerImage src="/assets/sneakers/1-2.png" />
        <SneakerImage src="/assets/sneakers/1-3.png" />
      </div>
    </div>
  );
});

const MobileSneakers = () => {
  return (
    <div className="flex md:hidden flex-wrap gap-2 w-full">
      <SneakerImage src="/assets/sneakers/0-0.png" />
      <SneakerImage src="/assets/sneakers/0-1.png" />
      <SneakerImage src="/assets/sneakers/0-2.png" />
      <SneakerImage src="/assets/sneakers/0-3.png" />
      <SneakerImage src="/assets/sneakers/1-0.png" />
      <SneakerImage src="/assets/sneakers/1-1.png" />
      <SneakerImage src="/assets/sneakers/1-2.png" />
      <SneakerImage src="/assets/sneakers/1-3.png" />
    </div>
  );
};
