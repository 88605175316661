import classNames from "classnames";

const Divider = ({ hidden, className }: any) => {
  return (
    <div className={classNames("h-full hidden xl:block", className)}>
      {hidden && <div className="h-[120px] w-[2.5px] bg-transparent" />}
      {!hidden && <div className="h-[120px] w-[2.5px] bg-secondary-text" />}
    </div>
  );
};

export default Divider;
