const GenericAnchor = ({ href, children }: any) => {
  return (
    <a
      href={href}
      target="_blank"
      className="w-fit hover:scale-110 transform duration-150"
    >
      {children}
    </a>
  );
};

export default GenericAnchor;
