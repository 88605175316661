import { Dialog, Transition } from "@headlessui/react";
import cn from "classnames";
import { Fragment, ReactElement } from "react";
import { MdClose } from "react-icons/md";

export interface PopupProps {
  opened: boolean;
  handlers: {
    open: () => void;
    close: () => void;
    toggle: () => void;
  };
}

interface ExtendedPopupProps extends PopupProps {
  // onClose?: () => void;
  size?: string;
  closeOnOverlay?: boolean;
  overlayOpacity?: string;
  className?: string;
  children: ReactElement | ReactElement[];
}

const Popup = (props: ExtendedPopupProps) => {
  const {
    opened,
    handlers,
    closeOnOverlay = false,
    size = "w-auto",
    overlayOpacity = "bg-opacity-90",
    className,
    children,
  } = props;

  return (
    <Transition appear show={opened} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          if (closeOnOverlay) handlers.close();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={cn("fixed inset-0 bg-black", {
              [overlayOpacity]: overlayOpacity,
            })}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full sm:min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={cn(
                  "h-full lg:h-auto flex flex-col justify-center transform bg-black border border-primary rounded-none text-left align-middle transition-all",
                  className,
                  { [size]: size, "w-full": size !== "w-auto" }
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Popup;

export const PopupHeader = ({ className, children }: any) => {
  return (
    <div
      className={cn(
        "flex justify-between items-center text-neutral-200 p-2 sm:p-4",
        className
      )}
    >
      {children}
    </div>
  );
};

export const PopupTitle = ({ className, children }: any) => {
  return (
    <Dialog.Title
      as="h3"
      className={cn(
        "text-base sm:text-lg text-neutral-200 font-bold m-0",
        className
      )}
    >
      {children}
    </Dialog.Title>
  );
};

export const PopupClose = ({ handlers }: any) => {
  return (
    <button type="button" onClick={handlers.close}>
      <MdClose className="w-8 h-8 text-primary" />
    </button>
  );
};
