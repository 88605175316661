export const wsLink = (service: string) => {
  return `https://wa.me/60123453466?text=Hello!%20I'm%20interested%20in%20learning%20more%20about%20${
    service !== "PU4LYF" ? `your%20${service}` : service
  }.%20Could%20you%20please%20provide%20more%20details?%20Thanks!`;
};

export const customsWsLink = (service: string) => {
  return `https://wa.me/60169299623?text=Hello!%20I'm%20interested%20in%20learning%20more%20about%20${
    service !== "PU4LYF" ? `your%20${service}` : service
  }.%20Could%20you%20please%20provide%20more%20details?%20Thanks!`;
};
