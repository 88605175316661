const LinkTitle = ({ children }: any) => {
  return (
    <div className="w-32 text-center mt-auto">
      <span className="text-text group-hover:text-primary font-light text-base xs:text-[20px]">
        {children}
      </span>
    </div>
  );
};

export default LinkTitle;
