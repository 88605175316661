import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import "../App.css";
import ImageWithFadeIn from "../components/FadeInImage";
import Header from "../components/Header";
import HorizontalDivider from "../components/HorizontalDivider";
import Layout from "../components/Layout";
import Popup, { PopupClose } from "../components/Popup";
import { wsLink } from "../utils/format";
import { Gallery, PopUpText } from "./label";

export const images = Array(16)
  .fill(0)
  .map((_, index) => {
    return {
      original: `/assets/agency/${index + 1}.webp`,
      thumbnail: `/assets/agency/${index + 1}.webp`,
    };
  });

function Agency() {
  const menuDisclosure = useDisclosure(false);
  const modalDisclosure = useDisclosure(false);
  const popUpDisclosure = useDisclosure(false);

  const [popUpContent, setPopUpContent] = useState("");

  return (
    <Layout menuDisclosure={menuDisclosure}>
      <main className="flex flex-col min-h-screen w-screen items-center justify-center bg-black">
        <Header menuDisclosure={menuDisclosure} />
        <div className="flex h-full lg:h-fit w-full justify-center items-center lg:pt-[84px] gap-12 md:gap-12 lg:gap-20 lgxl:gap-24 xl:gap-16 2xl:gap-20 sm:py-12 lg:py-0 px-10">
          <div className="flex flex-col justify-center items-center max-w-screen-lg md:py-0 py-6 md:pb-6">
            <ImageWithFadeIn
              src="/assets/label/pu4lyf-watermark.svg"
              alt="watermark"
            />
            <div className="flex w-full justify-center items-center gap-5 text-secondary-text py-8">
              <HorizontalDivider />
              <span className="whitespace-nowrap">FORMER CLIENTS</span>
              <HorizontalDivider />
            </div>
            <div className="flex flex-wrap gap-5 justify-center">
              <div className="flex flex-wrap justify-center gap-5">
                <BrandLogo src="/assets/brands/meta.svg" alt="meta" />
                <BrandLogo
                  src="/assets/brands/malaysia-airlines.svg"
                  alt="malaysia-airlines"
                />
                <BrandLogo src="/assets/brands/man-u.svg" alt="man-u" />
                <BrandLogo src="/assets/brands/jd-sports.svg" alt="jd-sports" />
                <BrandLogo
                  src="/assets/brands/universal-music-malaysia.svg"
                  alt="universal-music-malaysia"
                />
              </div>

              <div className="flex flex-wrap justify-center gap-5">
                <BrandLogo src="/assets/brands/astro.svg" alt="astro" />
                <BrandLogo src="/assets/brands/maggi.svg" alt="maggi" />
                <BrandLogo src="/assets/brands/foodpanda.svg" alt="foodpanda" />
                <BrandLogo src="/assets/brands/allianz.svg" alt="allianz" />
                <BrandLogo src="/assets/brands/starbucks.svg" alt="starbucks" />
              </div>

              <div className="flex flex-wrap justify-center gap-5">
                <BrandLogo src="/assets/brands/boost.svg" alt="boost" />
                <BrandLogo src="/assets/brands/kitkat.svg" alt="kitkat" />
                <BrandLogo src="/assets/brands/prism.svg" alt="prism" />
                <BrandLogo src="/assets/brands/nescafe.svg" alt="nescafe" />
                <BrandLogo src="/assets/brands/milo.svg" alt="milo" />
                <BrandLogo
                  src="/assets/brands/sacoor-blue.svg"
                  alt="sacoor-blue"
                />
              </div>

              <div className="flex flex-wrap justify-center gap-5">
                <BrandLogo src="/assets/brands/tm.svg" alt="tm" />
                <BrandLogo src="/assets/brands/guinness.svg" alt="guinness" />
                <BrandLogo src="/assets/brands/carlsberg.svg" alt="carlsberg" />
                <BrandLogo src="/assets/brands/hasbro.svg" alt="hasbro" />
                <BrandLogo src="/assets/brands/vespa.svg" alt="vespa" />
                <BrandLogo src="/assets/brands/prime.svg" alt="prime" />
                <BrandLogo src="/assets/brands/maybank.svg" alt="maybank" />
              </div>

              <div className="flex flex-wrap justify-center gap-5">
                <BrandLogo src="/assets/brands/unicef.svg" alt="unicef" />
                <BrandLogo src="/assets/brands/the-star.svg" alt="the-star" />
                <BrandLogo src="/assets/brands/spritzer.svg" alt="spritzer" />
                <BrandLogo src="/assets/brands/sunway.svg" alt="sunway" />
                <BrandLogo src="/assets/brands/spinup.svg" alt="spinup" />
              </div>
            </div>
            <div className="text-text font-serif text-sm flex flex-col justify-center items-center text-center gap-5 pt-8">
              <div>
                Content Curation & Coordination | Branding & Marketing Expertise
              </div>
              <div>
                With over a decade of experience in the music industry, I
                specialize in curating and coordinating content, producing
                layouts, and establishing comprehensive branding guidelines.
                I've had the privilege of managing two leading record labels,
                PU4LYF Entertainment Sdn. Bhd and PLASTIC Entertainment.
              </div>
              <div>
                Beyond music, my expertise extends into creating, branding,
                marketing, and producing content for businesses across various
                industries. I offer consultations on building a lasting brand
                image through tailored marketing and PR strategies. I help
                brands evolve with the times, crafting customized campaigns that
                resonate with the right target audience and drive meaningful
                results, ensuring a strong ROI for every project.
              </div>
            </div>

            <div className="hidden lg:flex flex-col flex-wrap text-primary justify-center items-center pt-6">
              <div className="flex flex-wrap gap-3">
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("digital");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/digital.svg"
                    alt="digital"
                  />
                </PopUpText>
                <div>|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("advertising");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/advertising.svg"
                    alt="advertising"
                  />
                </PopUpText>
                <div>|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("talent");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/talent.svg"
                    alt="talent"
                  />
                </PopUpText>
                <div>|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("kol");
                  }}
                >
                  <ImageWithFadeIn src="/assets/label/kol.svg" alt="kol" />
                </PopUpText>
              </div>
              <div className="flex flex-wrap gap-3">
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("video-audio");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/video-audio.svg"
                    alt="video-audio"
                  />
                </PopUpText>
                <div>|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("events");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/events.svg"
                    alt="events"
                  />
                </PopUpText>
                <div>|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("online");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/online.svg"
                    alt="online"
                  />
                </PopUpText>
              </div>
            </div>

            <div className="flex lg:hidden flex-col flex-wrap text-primary pt-6">
              <div className="flex flex-col md:flex-row flex-wrap gap-3 justify-center items-center">
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("digital");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/digital.svg"
                    alt="digital"
                  />
                </PopUpText>
                <div className="hidden md:block">|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("advertising");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/advertising.svg"
                    alt="advertising"
                  />
                </PopUpText>
                <div className="hidden md:block">|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("talent");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/talent.svg"
                    alt="talent"
                  />
                </PopUpText>
                <div className="hidden md:block">|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("kol");
                  }}
                >
                  <ImageWithFadeIn src="/assets/label/kol.svg" alt="kol" />
                </PopUpText>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("video-audio");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/video-audio.svg"
                    alt="video-audio"
                  />
                </PopUpText>
                <div className="hidden md:block">|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("events");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/events.svg"
                    alt="events"
                  />
                </PopUpText>
                <div className="hidden md:block">|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("online");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/online.svg"
                    alt="online"
                  />
                </PopUpText>
              </div>
            </div>
            <div className="flex flex-col gap-8 justify-center items-center pt-10">
              {/* <div
                className="border border-border select-none w-36 text-neutral-200 h-10 gap-2 px-3 min-w-20 items-center justify-center cursor-pointer flex font-light hover:text-primary hover:border-primary duration-150 transition"
                onClick={modalDisclosure[1].open}
              >
                GALLERY
              </div> */}
              <div className="flex gap-5">
                <div className="w-fit hover:scale-110 transform duration-150">
                  <a target="_blank" href={wsLink("Agency")}>
                    <ImageWithFadeIn
                      src="/assets/landing/Whatsapp.svg"
                      alt="Whatsapp"
                      className="h-10 cursor-pointer"
                    />
                  </a>
                </div>
                <div className="w-fit hover:scale-110 transform duration-150">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/pu4lyf.ent/"
                  >
                    <ImageWithFadeIn
                      src="/assets/landing/Instagram.svg"
                      alt="Instagram"
                      className="h-10 cursor-pointer"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Popup
          handlers={popUpDisclosure[1]}
          opened={popUpDisclosure[0]}
          size="max-w-screen-lg"
          closeOnOverlay
        >
          <div className="flex text-white justify-center items-center relative min-h-[500px] h-full w-full">
            <div className="absolute right-3 top-3">
              <PopupClose handlers={popUpDisclosure[1]} />
            </div>
            {popUpContent === "digital" && (
              <PopUpContent
                src="/assets/label/digital.svg"
                alt="digital"
                wsMsg="Digital%20Marketing"
              >
                With over seven years of expertise, we specialize in promoting
                products and services online through platforms such as websites,
                social media, EDMs, search engines, and digital billboards. Our
                strategies are designed to connect audiences with your brand,
                foster engagement, and turn interest into conversions.
              </PopUpContent>
            )}
            {popUpContent === "advertising" && (
              <PopUpContent
                src="/assets/label/advertising.svg"
                alt="advertising"
                wsMsg="Advertising"
              >
                Our team excels at crafting compelling narratives and delivering
                impactful messages through mass media, social media, billboards,
                and other mediums. We focus on capturing attention, engaging
                audiences and creating memorable campaigns that resonate with
                the right target audiences.
              </PopUpContent>
            )}
            {popUpContent === "talent" && (
              <PopUpContent
                src="/assets/label/talent.svg"
                alt="talent"
                wsMsg="Talent%20Management"
              >
                With more than 15 years of experience, we have successfully
                managed over 50 in-house artists and collaborated with
                internationally renowned talent for events, campaigns, and
                commercials.
              </PopUpContent>
            )}
            {popUpContent === "kol" && (
              <PopUpContent
                src="/assets/label/kol.svg"
                alt="kol"
                wsMsg="KOL%20Agency"
              >
                We manage and co-manage a diverse network of over 300
                multiracial Key Opinion Leaders (KOLs), ranging from Platinum to
                Macro and Micro influencers. Our aim is to drive impactful
                social media campaigns and commercials.
              </PopUpContent>
            )}
            {popUpContent === "video-audio" && (
              <PopUpContent
                src="/assets/label/video-audio.svg"
                alt="video-audio"
                wsMsg="Video%20and%20Audio%20Production"
              >
                Our in-house team of seasoned creatives has delivered
                high-quality video and audio production for over 100 projects.
                We collaborate with top-tier partners to produce videos for
                mainstream advertisements, music videos, short films, and social
                media content.
              </PopUpContent>
            )}
            {popUpContent === "events" && (
              <PopUpContent
                src="/assets/label/events.svg"
                alt="events"
                wsMsg="Events"
              >
                From intimate gatherings to large-scale arena events, we have
                organized, managed, produced, and promoted more than 60 events.
                Our expertise spans live, online, and streaming formats,
                engaging guests of over 10,000 people.
              </PopUpContent>
            )}
            {popUpContent === "online" && (
              <PopUpContent
                src="/assets/label/online.svg"
                alt="online"
                wsMsg="Online%20Partner"
              >
                We collaborate with film production houses, event organizers,
                and brands to amplify their promotional efforts. From teasers
                and trailers to audio releases, our campaigns have garnered
                millions of views and impressions, ensuring maximum reach and
                impact.
              </PopUpContent>
            )}
          </div>
        </Popup>
        <Gallery
          handlers={modalDisclosure[1]}
          opened={modalDisclosure[0]}
          images={images}
        />
      </main>
    </Layout>
  );
}

export default Agency;

const BrandLogo = ({ src, alt }: any) => {
  return <ImageWithFadeIn src={src} alt={alt} />;
};

const PopUpContent = ({ src, alt, wsMsg, children }: any) => {
  return (
    <div className="w-full flex flex-col gap-16 justify-center items-center p-6">
      <ImageWithFadeIn src={src} alt={alt} className="h-6 cursor-pointer" />
      <div className="max-w-[650px] font-normal text-center font-serif">
        {children}
      </div>
      {/* <a target="_blank" href={wsLink(wsMsg)}>
        <div className="border border-border select-none w-36 text-neutral-200 h-10 gap-2 px-3 min-w-20 items-center justify-center cursor-pointer flex font-light hover:text-primary hover:border-primary duration-150 transition">
          SIGN UP
        </div>
      </a> */}
    </div>
  );
};
