import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import "../App.css";
import ImageWithFadeIn from "../components/FadeInImage";
import Header from "../components/Header";
import Layout from "../components/Layout";
import { wsLink } from "../utils/format";

function Merch() {
  const navigate = useNavigate();
  const menuDisclosure = useDisclosure(false);

  return (
    <Layout menuDisclosure={menuDisclosure}>
      <main className="flex flex-col min-h-screen w-screen items-center justify-center bg-black">
        <Header menuDisclosure={menuDisclosure} />
        <div className="flex flex-col lg:flex-row h-full lg:h-fit w-full justify-center md:pt-0 lg:pt-[84px] gap-12 md:gap-12 lg:gap-20 lgxl:gap-24 xl:gap-16 2xl:gap-20 md:py-12 lg:py-0 px-4 md:px-10">
          <div className="flex flex-col md:flex-row gap-8 md:gap-16 max-w-screen-xl py-6 md:py-0">
            <div className="flex flex-col flex-1 basis-0 gap-3">
              <ImageWithFadeIn src="/assets/fbg/shirts.png" alt="shirts" />

              <div className="flex flex-col text-text font-serif text-sm gap-5 md:pl-6 text-center md:text-left">
                <div className="">
                  Established in 2005, FUTURE BRIGHT GANG began its journey as
                  PSYCHOLOGIKAL Apparels, making waves in the underground
                  streetwear scene with bold designs and a rebellious spirit.
                  Over the years, the brand has evolved, rebranding itself as
                  FUTURE BRIGHT GANG, while staying true to its roots of pushing
                  the boundaries of street fashion. With the tagline
                  "Contributing energy into street fashion since 2005," the
                  brand has sold over 10,000 t-shirts and merchandise combined,
                  all uniquely designed by its founder, the acclaimed rapper and
                  designer Rabbit Mac. FUTURE BRIGHT GANG is more than just a
                  brand, it's a movement that merges music, culture, and fashion
                  into one powerful force.
                </div>
                <div>
                  Check out the official store and our latest collections at{" "}
                  <a
                    href="https://futurebrightgang.com/"
                    className="transition duration-150 hover:text-primary"
                  >
                    www.futurebrightgang.com.
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between items-center flex-[0.6_1_0] xl:flex-[0.8_1_0] basis-0 gap-12">
              <ImageWithFadeIn
                className="hidden md:block"
                src="/assets/fbg/fbg.svg"
                alt="shirts"
              />
              <a
                target="_blank"
                href="https://futurebrightgang.com/"
                className="border border-border select-none w-44 text-neutral-200 h-10 gap-2 px-3 min-w-20 items-center justify-center cursor-pointer flex font-light hover:text-primary hover:border-primary duration-150 transition"
              >
                OFFICIAL STORE
              </a>
              <div className="flex flex-col gap-12">
                <div className="flex gap-5 items-center justify-center">
                  <div className="w-fit hover:scale-110 transform duration-150">
                    <a
                      target="_blank"
                      href={wsLink("Merch")}
                      className="h-10 cursor-pointer"
                    >
                      <ImageWithFadeIn
                        src="/assets/landing/Whatsapp.svg"
                        alt="Whatsapp"
                        className="h-10 cursor-pointer"
                      />
                    </a>
                  </div>
                  <div className="w-fit hover:scale-110 transform duration-150">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/futurebrightgang/"
                    >
                      <ImageWithFadeIn
                        src="/assets/landing/Instagram.svg"
                        alt="Instagram"
                        className="h-10 cursor-pointer hover:scale-110 transform duration-150"
                      />
                    </a>
                  </div>
                </div>
                <div className="text-text flex flex-col items-center gap-1">
                  <span>DESIGNED BY:</span>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/rabbit.mac/"
                  >
                    <ImageWithFadeIn
                      className="w-fit"
                      src="/assets/signature/sign.svg"
                      alt="RABBITMAC"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default Merch;

{
  /* <ImageWithFadeIn src="/assets/shirts/0-0.png" alt="shirts" />
            <ImageWithFadeIn src="/assets/shirts/0-1.png" alt="shirts" />
            <ImageWithFadeIn src="/assets/shirts/0-2.png" alt="shirts" />
            <ImageWithFadeIn src="/assets/shirts/1-0.png" alt="shirts" />
            <ImageWithFadeIn src="/assets/shirts/1-1.png" alt="shirts" />
            <ImageWithFadeIn src="/assets/shirts/1-2.png" alt="shirts" />
            <ImageWithFadeIn src="/assets/shirts/1-3.png" alt="shirts" /> */
}
