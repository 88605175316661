import { Dialog, Tab, Transition } from "@headlessui/react";
import cn from "classnames";
import { Fragment, ReactElement } from "react";
import { MdClose } from "react-icons/md";

export interface ModalProps {
  opened: boolean;
  handlers: {
    open: () => void;
    close: () => void;
    toggle: () => void;
  };
}

interface ExtendedModalProps extends ModalProps {
  // onClose?: () => void;
  size?: string;
  closeOnOverlay?: boolean;
  overlayOpacity?: string;
  className?: string;
  children: ReactElement | ReactElement[];
}

const Modal = (props: ExtendedModalProps) => {
  const {
    opened,
    handlers,
    closeOnOverlay = false,
    size = "w-auto",
    overlayOpacity = "bg-opacity-90",
    className,
    children,
  } = props;

  return (
    <Transition appear show={opened} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          if (closeOnOverlay) handlers.close();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={cn("fixed inset-0 bg-black", {
              [overlayOpacity]: overlayOpacity,
            })}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex h-full sm:min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={cn(
                  "h-full lg:h-auto relative flex flex-col transform rounded-md bg-transparent text-left align-middle transition-all overflow-hidden justify-center",
                  className,
                  { [size]: size, "w-full": size !== "w-auto" }
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;

export const ModalHeader = ({ className, children }: any) => {
  return (
    <div
      className={cn(
        "flex justify-between items-center text-neutral-200 p-2 sm:p-4",
        className
      )}
    >
      {children}
    </div>
  );
};

export const ModalTitle = ({ className, children }: any) => {
  return (
    <Dialog.Title
      as="h3"
      className={cn(
        "text-base sm:text-lg text-neutral-200 font-bold m-0",
        className
      )}
    >
      {children}
    </Dialog.Title>
  );
};

export const ModalClose = ({ handlers }: any) => {
  return (
    <button type="button" onClick={handlers.close}>
      <MdClose className="w-8 h-8 text-primary" />
    </button>
  );
};

export const ModalTabList = ({ children }: any) => {
  return (
    //
    <Tab.List className="tab-list overflow-x-auto flex bg-brand-background border-b-[1px] border-b-brand-border">
      {children}
    </Tab.List>
  );
};

export const ModalTab = ({ children }: any) => {
  return (
    <Tab
      className={({ selected }) =>
        cn(
          "p-2 sm:p-4 text-sm sm:text-base font-medium leading-5 text-neutral-200",
          {
            "border-b-[1px] bg-brand-background-dark border-b-brand-border":
              selected,
            "text-neutral-400 hover:bg-white/[0.12] hover:text-neutral-200":
              !selected,
          }
        )
      }
    >
      {children}
    </Tab>
  );
};
