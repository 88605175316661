import { wsLink } from "../utils/format";
import ImageWithFadeIn from "./FadeInImage";
import GenericAnchor from "./GenericAnchor";

const Footer = () => {
  return (
    <div className="w-full block lg:absolute bottom-0 left-0 px-4 py-6 sm:px-6 sm:py-8 md:px-10 md:py-12 pt-6">
      <div className="w-full flex flex-col items-center justify-center gap-3 xs:gap-6">
        <ImageWithFadeIn
          src="/assets/landing/footer.svg"
          alt="A PU4LYF COMPANY"
          className="w-36 sm:w-48 h-5"
        />
        <div className="flex items-center gap-3.5 xs:gap-5">
          <GenericAnchor href="https://www.youtube.com/@PU4LYF">
            <ImageWithFadeIn
              src="/assets/landing/Youtube.svg"
              alt="Youtube"
              className="h-5 cursor-pointer"
            />
          </GenericAnchor>
          <GenericAnchor href={wsLink("PU4LYF")}>
            <ImageWithFadeIn
              src="/assets/landing/Whatsapp.svg"
              alt="Whatsapp"
              className="h-6 cursor-pointer"
            />
          </GenericAnchor>
          <GenericAnchor href="https://www.instagram.com/pu4lyf.ent/">
            <ImageWithFadeIn
              src="/assets/landing/Instagram.svg"
              alt="Instagram"
              className="h-6 cursor-pointer"
            />
          </GenericAnchor>
          <GenericAnchor href="https://www.linkedin.com/in/rabbitmac">
            <ImageWithFadeIn
              src="/assets/landing/LinkedIn.svg"
              alt="LinkedIn"
              className="h-6 cursor-pointer"
            />
          </GenericAnchor>
          <GenericAnchor href="https://www.twitter.com/PU4LYF_ent">
            <ImageWithFadeIn
              src="/assets/landing/Twitter.svg"
              alt="Twitter"
              className="h-6 cursor-pointer"
            />
          </GenericAnchor>
          <GenericAnchor href="https://www.facebook.com/PU4LYF.Entertainment">
            <ImageWithFadeIn
              src="/assets/landing/Facebook.svg"
              alt="Facebook"
              className="h-6 cursor-pointer"
            />
          </GenericAnchor>
          <GenericAnchor href="https://en.wikipedia.org/wiki/Rabbit_Mac">
            <ImageWithFadeIn
              src="/assets/landing/Wiki.png"
              alt="Wiki"
              className="h-6 cursor-pointer"
            />
          </GenericAnchor>
        </div>
        <div className="text-center">
          <span
            className="text-text font-light text-xs xs:text-sm"
            style={{ fontFamily: "Arial Narrow" }}
          >
            All rights reserved PU4LYF Entertainment Sdn. Bhd.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
