import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import classNames from "classnames";
import { Fragment } from "react";
import ImageWithFadeIn from "./FadeInImage";
import NavDrawer from "./NavDrawer";

interface LayoutProps {
  menuDisclosure: ReturnType<typeof useDisclosure>;
  children: any;
}

const Layout = ({ menuDisclosure, children }: LayoutProps) => {
  const hideSideNav = useMediaQuery("(max-height: 720px)");

  return (
    <Fragment>
      <div className="flex">
        <div className="hidden lg:flex flex-col min-h-screen justify-between lg:min-h-full overflow-auto min-w-[84px] bg-black border-r border-primary top-0 left-0 text-secondary-text z-30">
          <div
            className={classNames(
              "min-h-[84px] flex bg-primary justify-center cursor-pointer group",
              {
                ["h-full items-start pt-8"]: hideSideNav,
                ["items-center"]: !hideSideNav,
              }
            )}
            onClick={menuDisclosure[1].toggle}
          >
            <ImageWithFadeIn
              src="/assets/landing/menu.svg"
              className="transform duration-150 group-hover:rotate-12"
            />
          </div>
          {!hideSideNav && (
            <div className="pb-6 flex flex-col-reverse whitespace-nowrap fixed bottom-0 w-[83px]">
              <SideNavItem href="/">HOME</SideNavItem>
              <SideNavItem href="/agency">AGENCY</SideNavItem>
              <SideNavItem href="/label">LABEL</SideNavItem>
              <SideNavItem href="/merch">MERCH</SideNavItem>
              <SideNavItem href="/customs">CUSTOMS</SideNavItem>
              <SideNavItem href="/studio">STUDIO</SideNavItem>
              {/* <SideNavItem href="/workshop">WORKSHOP</SideNavItem>
              <SideNavItem href="/podcast">PODCAST</SideNavItem> */}
            </div>
          )}
        </div>
        {children}
      </div>
      <NavDrawer menuDisclosure={menuDisclosure} />
    </Fragment>
  );
};

export default Layout;

const SideNavItem = ({ href, children, className }: any) => {
  const textSm = useMediaQuery("(max-height: 920px)");
  const gapSm = useMediaQuery("(max-height: 840px)");
  return (
    <a
      href={href}
      className={classNames(
        "rotate-180 flex items-center justify-center cursor-pointer duration-150 hover:bg-primary hover:text-black",
        className,
        {
          ["text-sm"]: textSm,
          ["py-4"]: gapSm,
          ["py-6"]: !gapSm,
        }
      )}
      style={{ writingMode: "vertical-rl" }}
    >
      {children}
    </a>
  );
};
