import LinkTitle from "./LinkTitle";

const SectionContainer = ({ children, label, href }: any) => {
  return (
    <a
      href={href}
      className="flex-1 basis-0 xs:flex-none max-h-[179px] xs:max-h-none overflow-visible"
    >
      <div className="group flex flex-col items-center justify-center gap-4 md:gap-10 h-full cursor-pointer">
        <div className="flex-1 basis-0 xs:flex-none lg:min-h-[120px] items-center flex transform duration-150 group-hover:scale-110 overflow-visible">
          {children}
        </div>
        <LinkTitle>{label}</LinkTitle>
      </div>
    </a>
  );
};

export default SectionContainer;
