const HorizontalDivider = ({ hidden }: any) => {
  return (
    <div className="h-full w-full md:w-auto block">
      {hidden && (
        <div className="w-full md:w-[122.93px] h-[2.5px] bg-transparent" />
      )}
      {!hidden && (
        <div className="w-full md:w-[122.93px] h-[2.5px] bg-secondary-text" />
      )}
    </div>
  );
};

export default HorizontalDivider;
