import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import ImageWithFadeIn from "./FadeInImage";

interface HeaderProps {
  menuDisclosure: ReturnType<typeof useDisclosure>;
}

const Header = ({ menuDisclosure }: HeaderProps) => {
  const navigate = useNavigate();
  return (
    <div className="w-full block z-20 lg:absolute top-0 left-0 px-4 py-6 sm:px-6 lg:pl-[84px] lg:pr-12 md:py-[22px] pb-12 h-10 sm:h-[84px]">
      <div className="lg:pl-10 h-full">
        <div className="w-full flex flex-nowrap md:flex-wrap gap-6 lg:gap-10 justify-between">
          <div className="flex flex-wrap items-end gap-4 lg:gap-6">
            <ImageWithFadeIn
              src="/assets/landing/Logo.svg"
              alt="Logo"
              className="h-8 xs:h-10 sm:w-auto cursor-pointer"
              onClick={() => navigate("/")}
            />
            <Divider />
            <span className="text-text font-light text-sm leading-none hidden sm:block">
              FROM BEATS TO BUSINESS
            </span>
          </div>
          {window?.location?.pathname !== "/" && (
            <div
              className="border border-border select-none w-36 text-neutral-200 h-10 gap-2 px-3 min-w-20 items-center justify-center cursor-pointer hidden lg:flex font-light hover:text-primary hover:border-primary duration-150 transition"
              onClick={() => navigate("/")}
            >
              HOME
            </div>
          )}
          <div
            className="bg-primary h-8 xs:h-10 text-sm xs:text-base gap-2 px-3 min-w-20 select-none items-center justify-center cursor-pointer flex lg:hidden"
            onClick={menuDisclosure[1].toggle}
          >
            <ImageWithFadeIn
              src="/assets/landing/menu.svg"
              className="h-4 w-4 xs:h-5 xs:w-5"
            />
            MENU
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

const Divider = ({ hidden }: any) => {
  return (
    <div className="h-full hidden sm:block">
      {!hidden && <div className="h-full w-[2.5px] bg-border" />}
    </div>
  );
};
