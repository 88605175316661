import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Agency from "./pages/agency";
import Customs from "./pages/customs";
import Label from "./pages/label";
import Landing from "./pages/landing";
import Merch from "./pages/merch";
import Studio from "./pages/studio";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/customs" element={<Customs />} />
        <Route path="/agency" element={<Agency />} />
        <Route path="/label" element={<Label />} />
        <Route path="/merch" element={<Merch />} />
        <Route path="/studio" element={<Studio />} />
        {/* <Route path="/workshop" element={<Workshop />} />
        <Route path="/podcast" element={<Podcast />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
