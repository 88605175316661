import { useDisclosure } from "@mantine/hooks";
import "../App.css";
import Divider from "../components/Divider";
import ImageWithFadeIn from "../components/FadeInImage";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Layout from "../components/Layout";
import SectionContainer from "../components/SectionContainer";

function Landing() {
  const menuDisclosure = useDisclosure(false);

  return (
    <Layout menuDisclosure={menuDisclosure}>
      <main className="flex flex-col min-h-screen lg:h-screen w-screen items-center justify-center bg-black">
        {/* <Welcome /> */}
        <Header menuDisclosure={menuDisclosure} />
        <div className="flex flex-col justify-center gap-4 xs:gap-6 sm:gap-8 md:gap-12 lg:gap-16 sm:py-12 lg:py-0 px-10 pt-6 lg:pt-0 flex-1 basis-0 lg:flex-none overflow-visible">
          <div className="flex flex-col lg:flex-row lg:h-fit justify-center gap-4 xs:gap-6 sm:gap-8 md:gap-12 lg:gap-20 xl:gap-12 2xl:gap-16 3xl:gap-24">
            <SectionContainer label="AGENCY" href="/agency">
              <ImageWithFadeIn
                src="/assets/landing/Agency.svg"
                className="flex-1 basis-0 xs:flex-none h-[100px] md:h-auto lg:h-[90px] 2xl:h-auto"
                alt="Agency"
              />
            </SectionContainer>
            <Divider />
            <SectionContainer label="LABEL" href="/label">
              <ImageWithFadeIn
                src="/assets/landing/Label.svg"
                className="flex-1 basis-0 xs:flex-none h-[100px] md:h-auto lg:h-[90px] 2xl:h-auto"
                alt="Label"
              />
            </SectionContainer>
            <Divider />
            <SectionContainer label="MERCH" href="/merch">
              <ImageWithFadeIn
                src="/assets/landing/Merch.svg"
                className="flex-1 basis-0 xs:flex-none h-[100px] md:h-auto lg:h-[90px] 2xl:h-auto"
                alt="Merch"
              />
            </SectionContainer>
          </div>
          <div className="flex flex-col lg:flex-row lg:h-fit justify-center gap-4 xs:gap-6 sm:gap-8 md:gap-12 lg:gap-20 xl:gap-12 2xl:gap-16 3xl:gap-24">
            <SectionContainer label="CUSTOMS" href="/customs">
              <ImageWithFadeIn
                src="/assets/landing/Customs.svg"
                className="flex-1 basis-0 xs:flex-none h-[100px] md:h-auto lg:h-[80px] 2xl:h-auto"
                alt="Custom"
              />
            </SectionContainer>
            <Divider />
            <SectionContainer label="STUDIO" href="/studio">
              <ImageWithFadeIn
                src="/assets/landing/Studio.svg"
                className="flex-1 basis-0 xs:flex-none md:h-[80px] lg:h-auto 2xl:min-h-[85px]"
                alt="Studio"
              />
            </SectionContainer>
            {/* <Divider />
            <SectionContainer label="WORKSHOP" href="/workshop">
              <ImageWithFadeIn
                src="/assets/landing/Futureclass.svg"
                className="flex-1 basis-0 xs:flex-none md:h-[70px] lg:h-auto 2xl:min-h-[80px]"
                alt="Workshop"
              />
            </SectionContainer>
            <Divider />
            <SectionContainer label="PODCAST" href="/podcast">
              <ImageWithFadeIn
                src="/assets/landing/Podlyf.svg"
                className="flex-1 basis-0 xs:flex-none md:h-[70px] lg:h-auto 2xl:min-h-[80px]"
                alt="Podcast"
              />
            </SectionContainer> */}
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  );
}

export default Landing;
