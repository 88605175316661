import { useDisclosure } from "@mantine/hooks";
import "../App.css";
import ImageWithFadeIn from "../components/FadeInImage";
import Header from "../components/Header";
import Layout from "../components/Layout";

// import stylesheet if you're not already using CSS @import
import { useState } from "react";
import Modal, { ModalClose } from "../components/Modal";
import Popup, { PopupClose } from "../components/Popup";
import "../styles/image-gallery.css";
import { Gallery } from "./label";

export const images = Array(9)
  .fill(0)
  .map((_, index) => {
    return {
      original: `/assets/studio/${index + 1}.webp`,
      thumbnail: `/assets/studio/${index + 1}.webp`,
    };
  });

function Studio() {
  const menuDisclosure = useDisclosure(false);
  const modalDisclosure = useDisclosure(false);
  const popUpDisclosure = useDisclosure(false);
  const priceDisclosure = useDisclosure(false);

  const [popUpContent, setPopUpContent] = useState("");

  return (
    <Layout menuDisclosure={menuDisclosure}>
      <main className="flex flex-col min-h-screen w-screen items-center justify-center bg-black">
        <Header menuDisclosure={menuDisclosure} />
        <div className="flex h-full lg:h-fit w-full justify-center items-center lg:pt-[84px] gap-12 md:gap-12 lg:gap-20 lgxl:gap-24 xl:gap-16 2xl:gap-20 sm:py-12 lg:py-0 px-10">
          <div className="flex flex-col justify-center items-center max-w-screen-lg md:py-0 py-6 md:pb-6">
            <ImageWithFadeIn src="/assets/studio/studio.svg" alt="watermark" />
            <ImageWithFadeIn
              src="/assets/studio/improved-graphics.png"
              className="pt-12"
              alt="watermark"
            />
            <div className="flex flex-wrap justify-center gap-5 font-thin text-secondary-text pt-8">
              <div className="flex flex-wrap gap-5 justify-center">
                <div
                  className="w-fit flex items-center hover:scale-105 transform duration-150 cursor-pointer"
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("composition");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/composition.svg"
                    alt="pr"
                  />
                </div>
                <div className="text-primary font-bold hidden ls:block">|</div>
                <div
                  className="w-fit flex items-center hover:scale-105 transform duration-150 cursor-pointer"
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("mixing");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/mixing.svg"
                    alt="mixing"
                  />
                </div>
              </div>
            </div>
            <div className="text-text font-serif text-sm flex flex-col justify-center items-center text-center gap-5 pt-8">
              <div>
                Studio:777 officially launched it's premium audio production,
                mixing, and mastering services, starting January 1, 2025.
                Spearheaded by the renowned and multiple award-win- ning
                producer and audio engineer, RABBITMAC. Studio:777 aims to set
                the current sounding standard with a reputation for crafting
                top-tier soundscapes that resonate across genres. RABBITMAC
                promises to bring unparalleled expertise and a creative touch to
                every project.
              </div>
              <div>
                Whether you're an artist, band, or content creator, Studio:777
                provides a tailored approach to ensure your music stands out
                with a professional sound. From precise mixing to world-class
                mastering, it's time to work with one of the most sought-after
                names in the business.
              </div>
              <div>
                The service is now open for bookings now and get your inquiries
                now to elevate your sound and secure your place in the hands of
                a true audio visionary.{" "}
              </div>
            </div>
            {/* Composition With the expertise of renowned record producer, sound engineer, and creative composer Rabbit Mac, who has produced over 100 major tracks, success is guaranteed. From studio albums and singles to soundtracks and jingles, we deliver excellence with every project.
Mixing and Mastering With over 10 years of experience, our award-winning rapper, producer, and sound engineer Rabbit Mac excels at handling up to 150-layer mixing sessions, delivering loud, dynamic, and crystal-clear masters. Using advanced mixing techniques and Solid State Logic hardware processing, we ensure your tracks achieve the professional quality and global sound standards of Billboard-charting records. */}
            <div className="flex flex-col justify-center items-center gap-7 pt-10">
              <div className="w-fit hover:scale-110 transform duration-150">
                <a
                  target="_blank"
                  href="https://www.instagram.com/stu.7.7.7?igsh=MWFzcXY1c2I4bWM2ag%3D%3D&utm_source=qr"
                >
                  <ImageWithFadeIn
                    src="/assets/landing/Instagram.svg"
                    alt="Instagram"
                    className="h-10 cursor-pointer"
                  />
                </a>
              </div>
              <div className="flex gap-5 flex-wrap items-center justify-center">
                <div
                  className="border border-border select-none w-36 text-neutral-200 h-10 gap-2 px-3 min-w-20 items-center justify-center cursor-pointer flex font-light hover:text-primary hover:border-primary duration-150 transition"
                  onClick={priceDisclosure[1].open}
                >
                  PRICE
                </div>
                <a
                  href="mailto:bookatstudio777@gmail.com"
                  onClick={(event) => {
                    event.preventDefault(); // Prevent the default action

                    // Email recipient
                    var recipient = "bookatstudio777@gmail.com";

                    // For Android: Gmail app deep link using 'intent://'
                    var androidGmailUrl =
                      "intent://send?to=" +
                      recipient +
                      "#Intent;package=com.google.android.gm;end";

                    // For iOS: Gmail app URL scheme
                    var iosGmailUrl = "googlegmail://co?to=" + recipient;

                    // Fallback to Gmail web for other platforms
                    var fallbackUrl =
                      "https://mail.google.com/mail/?view=cm&fs=1&to=" +
                      recipient;

                    // Detect if the user is on a mobile device
                    var isAndroid = /android/i.test(navigator.userAgent);
                    var isIOS = /iphone|ipod|ipad/i.test(navigator.userAgent);

                    if (isAndroid) {
                      // Try to open the Gmail app on Android
                      window.location.href = androidGmailUrl;
                    } else if (isIOS) {
                      // Try to open the Gmail app on iOS
                      window.location.href = iosGmailUrl;
                    } else {
                      // For desktop or unsupported platforms, open Gmail in the browser
                      window.location.href = fallbackUrl;
                    }
                  }}
                >
                  <div className="border border-border select-none w-36 text-neutral-200 h-10 gap-2 px-3 min-w-20 items-center justify-center cursor-pointer flex font-light hover:text-primary hover:border-primary duration-150 transition">
                    BOOK NOW
                  </div>
                </a>

                <div
                  className="border border-border select-none w-36 text-neutral-200 h-10 gap-2 px-3 min-w-20 items-center justify-center cursor-pointer flex font-light hover:text-primary hover:border-primary duration-150 transition"
                  onClick={modalDisclosure[1].open}
                >
                  GALLERY
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          handlers={priceDisclosure[1]}
          opened={priceDisclosure[0]}
          closeOnOverlay
        >
          <div className="absolute top-3 right-3 z-10">
            <ModalClose handlers={priceDisclosure[1]} />
          </div>
          <div className="relative w-screen flex justify-center items-center">
            <ImageWithFadeIn
              src="/assets/studio/charges.png"
              className="max-h-screen object-cover p-4"
              alt="Charges"
            />
          </div>
        </Modal>
        <Popup
          handlers={popUpDisclosure[1]}
          opened={popUpDisclosure[0]}
          size="max-w-screen-lg"
          closeOnOverlay
        >
          <div className="flex text-white justify-center items-center relative min-h-[500px] h-full w-full">
            <div className="absolute right-3 top-3">
              <PopupClose handlers={popUpDisclosure[1]} />
            </div>
            {popUpContent === "composition" && (
              <div className="w-full flex flex-col gap-12 justify-center items-center pt-3 pb-6 px-6">
                <ImageWithFadeIn
                  src="/assets/label/composition.svg"
                  alt="Studio"
                  className="h-6 cursor-pointer"
                />
                <div className="max-w-[600px] font-normal text-center font-serif">
                  With the expertise of renowned record producer, sound
                  engineer, and creative composer Rabbit Mac, who has produced
                  over 100 major tracks, success is guaranteed. From studio
                  albums and singles to soundtracks and jingles, we deliver
                  excellence with every project.
                </div>
              </div>
            )}
            {popUpContent === "mixing" && (
              <div className="w-full flex flex-col gap-12 justify-center items-center pt-3 pb-6 px-6">
                <ImageWithFadeIn
                  src="/assets/label/mixing.svg"
                  alt="Mixing"
                  className="h-6 cursor-pointer"
                />
                <div className="max-w-[600px] font-normal text-center font-serif">
                  With over 10 years of experience, our award-winning rapper,
                  producer, and sound engineer Rabbit Mac excels at handling up
                  to 150-layer mixing sessions, delivering loud, dynamic, and
                  crystal-clear masters. Using advanced mixing techniques and
                  Solid State Logic hardware processing, we ensure your tracks
                  achieve the professional quality and global sound standards of
                  Billboard-charting records.
                </div>
              </div>
            )}
          </div>
        </Popup>
        <Gallery
          handlers={modalDisclosure[1]}
          opened={modalDisclosure[0]}
          images={images}
        />
      </main>
    </Layout>
  );
}

export default Studio;
