import classNames from "classnames";
import { cloneElement, Fragment, useEffect, useState } from "react";

const ImageWithFadeIn = ({ src, alt, ...props }: any) => {
  const [isLoaded, setIsLoaded] = useState(false);

  // Handle image load event
  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <img
      {...props}
      src={src}
      alt={alt}
      onLoad={handleImageLoad}
      className={classNames(
        "fade-in-image",
        {
          "fade-in": isLoaded,
        },
        props.className
      )}
    />
  );
};

export const FadeIn = ({ children, ...props }: any) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Fragment>
      {cloneElement(children, {
        ...props,
        className: classNames(
          "transition-opacity duration-300 ease-in",
          {
            "opacity-1": isLoaded,
            "opacity-0": !isLoaded,
          },
          props.className
        ),
      })}
    </Fragment>
  );
};

export default ImageWithFadeIn;
